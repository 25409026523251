<template>
  <v-btn fab outlined color="cd_color_14" class="mr-4" @click="goTo()">
    <v-icon dark color="light"> $arrow-left</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "AppBarReturn",
  methods: {
    goTo() {
      this.$router.go(-1);
    },
  },
};
</script>
